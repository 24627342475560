<template>
    <div class="bk-wid" :class="{ 'mg-top60': $route.path === '/', 'infoCenter': isFooterClass }">
        <div class="bk-wid-134 bk-footer">
            <div class="bk-footer-partner" v-if="$route.path === '/'">
                <div class="bk-footer-title">{{ $t('components.Footer.751010-0') }}</div>
                <ul>
                    <li>
                        <img src="@/assets/images/partner01.svg" />
                        <p>{{ $t('components.Footer.751010-1') }}</p>
                    </li>
                    <li>
                        <img src="@/assets/images/partner02.svg" />
                        <p>{{ $t('components.Footer.751010-2') }}</p>
                    </li>
                </ul>
            </div>
            <div class="bk-footer-main" :class="{ 'line-no': $route.path !== '/' }">
                <div class="bk-footer-left">
                    <div class="footer-title">
                        <span>{{ $t('components.Footer.751010-3') }}</span>
                    </div>
                    <p>BK8 Sports (9 Abraham De Veerstraat Willemstad, Curaçao) {{ $t('components.Footer.751010-4') }}
                        {{ $t('components.Footer.751010-5') }}</p>
                    <div class="footer-title">
                        <span>{{ $t('components.Footer.751010-6') }}</span>
                        <img src="@/assets/images/xkz.svg" class="icon" />
                    </div>
                    <img src="@/assets/images/xkz01.svg" class="xkz" />
                </div>
                <div class="bk-footer-menu">
                    <!-- {{ gamePlayt }} -->
                    <dl>
                        <dt>{{ $t('components.Footer.751010-7') }}</dt>
                        <dd v-for="(item, index) in games" :key="index" @click="$router.push(item.link)">
                            {{ item.gameTypeName }}
                        </dd>
                    </dl>
                    <dl>
                        <dt>{{ $t('components.Footer.751010-8') }}</dt>
                        <dd @click="$router.push('/about')">{{ $t('components.Footer.751010-9') }}</dd>
                        <!-- <dd>VIP</dd> -->
                        <dd @click="$router.push('/contact')">{{ $t('components.Footer.751010-10') }}</dd>
                        <dd @click="$router.push('/responsible-gaming')">{{ $t('components.Footer.751010-11') }}</dd>
                        <dd @click="$router.push('/info-center/faq#general')">{{ $t('components.Footer.751010-12') }}</dd>
                        <dd @click="$router.push('/info-center/tnc#terms')">{{ $t('components.Footer.751010-13') }}</dd>
                    </dl>
                </div>
                <div class="bk-footer-right">
                    <div class="bk-lang" @click.stop="tabLang">
                        <img class="bk-lang-icon" src="@/assets/images/gq.svg" />
                        <span class="name">{{ $t('components.Footer.751010-14') }}</span>
                        <span class="arrow">
                            <img src="@/assets/images/arrow.svg" />
                        </span>
                    </div>
                    <div class="footer-title mg-top110">
                        <span>{{ $t('components.Footer.751010-15') }}</span>
                    </div>
                    <div class="footer-pz-list">
                        <img src="@/assets/images/license/01.svg" />
                        <img src="@/assets/images/license/02.svg" />
                        <img src="@/assets/images/license/03.svg" />
                        <img src="@/assets/images/license/04.svg" />
                        <img src="@/assets/images/license/05.svg" />
                    </div>
                </div>
            </div>
            <div class="bk-footer-main bk-transter-row">
                <div class="bk-transter">
                    <div class="footer-title">
                        <span>{{ $t('components.Footer.751010-16') }}</span>
                    </div>
                    <div class="bk-transter-list">
                        <img class="one" src="@/assets/images/footer/01.svg" />
                        <img src="@/assets/images/footer/02.svg" />
                        <img src="@/assets/images/footer/03.svg" />
                        <img src="@/assets/images/footer/04.svg" />
                        <img src="@/assets/images/footer/05.png" />
                    </div>
                </div>
                <div class="bk-transter">
                    <div class="footer-title">
                        <span>{{ $t('components.Footer.751010-17') }}</span>
                    </div>
                    <div class="bk-transter-list">
                        <img src="@/assets/images/footer/06.svg" />
                        <img src="@/assets/images/footer/07.svg" />
                        <img src="@/assets/images/footer/08.svg" />
                        <img src="@/assets/images/footer/09.svg" />
                    </div>
                </div>
                <div class="bk-transter">
                    <div class="footer-title">
                        <span>{{ $t('components.Footer.751010-18') }}</span>
                    </div>
                    <div class="bk-transter-list bk-transter-flex">
                        <img src="@/assets/images/footer/10.svg" />
                        <img src="@/assets/images/footer/11.svg" />
                    </div>
                </div>
                <div class="bk-transter">
                    <div class="footer-title">
                        <span>{{ $t('components.Footer.751010-11') }}</span>
                    </div>
                    <div class="bk-transter-list">
                        <img class="one" src="@/assets/images/footer/12.svg" />
                        <img src="@/assets/images/footer/13.svg" />
                        <img src="@/assets/images/footer/14.svg" />
                    </div>
                </div>
            </div>
            <div class="bk-footer-copy">
                {{ $t('components.Footer.751010-19') }}
            </div>
        </div>
    </div>
</template>
<script>
import { mapState } from 'vuex'
// const pcUrl = ['https://www.baidu.com', 'https://www.google.com.cn']
// const h5Url = ['https://www.baidu.com', 'https://www.google.com.cn']
// if (/(iPhone|iPad|iPod|iOS|Android)/i.test(navigator.userAgent)) {
//     const url = h5Url[Math.floor((Math.random() * h5Url.length))]
//     location.href = url
// } else {
//     const url = pcUrl[Math.floor((Math.random() * pcUrl.length))]
//     location.href = url
// }
export default {
    data() {
        return {
            games: null,
            isFooter: false,
            isFooterClass: false
        }
    },
    computed: {
        ...mapState(['gamePlayt', 'isLogin'])
    },
    watch: {
        gamePlayt(val) {
            if (val) {
                this.initInfo()
            }
        },
        $route() {
            this.initInfoWeb()
        }
    },
    mounted() {
    },
    methods: {
        tabLang() {
            if (!this.isLogin) {
                this.$confirm(this.$t('components.Header.656206-17'), this.$t('components.Header.656206-18'), {
                    confirmButtonText: this.$t('components.Header.656206-19'),
                    showCancelButton: false,
                    customClass: "el-pop-wid",
                })
                    .then(() => {
                        this.$router.push("/register");
                    })
                    .catch(() => {
                        console.log(11);
                    });
                return
            }
            this.$emit('updateHeader', true)
            // this.$store.dispatch('setLangMenusAc', true)
        },
        initInfoWeb() {
            const isClass = this.$route.path.includes('/myaccount')
            if (isClass) {
                this.isFooterClass = true
            } else {

                this.isFooterClass = false
            }
        },
        initInfo() {
            this.games = this.gamePlayt.slice(0, 7)
        },

    }
}
</script>
<style lang="scss">
.bk-footer {
    &-partner {
        display: flex;
        flex-direction: column;
        align-items: flex-start;

        ul {
            display: flex;
            align-items: center;
            margin-top: 24px;

            li {
                margin-right: 24px;

                img {
                    height: 62px;
                }

                p {
                    margin-top: 10px;
                }
            }
        }
    }

    &-title {
        font-size: 13px;
    }

    &-main {
        display: flex;
        margin: 40px auto 0;
        padding: 42px 0px 46px;
        position: relative;
        border-top: 1px solid #dde0e0;
    }

    &-left {
        display: flex;
        flex-direction: column;
        width: 400px;

        p {
            color: rgb(134, 151, 162);
            width: 250px;
            line-height: 1.15;
            text-align: left;
            margin-bottom: 44px;
            font-size: 12px;
        }

        .xkz {
            width: 140px;
        }
    }

    &-menu {
        display: flex;
        align-items: flex-start;
        flex: 1;

        dl {
            width: 200px;
            text-align: left;

            dt {
                font-size: 12px;
                margin-bottom: 20px;
            }

            dd {
                color: rgb(134, 151, 162);
                margin-bottom: 15px;
                font-size: 15px;
                cursor: pointer;

                &:hover {
                    color: #0066cc;
                    text-decoration: underline;
                }
            }
        }
    }

    &-right {
        .bk-lang {
            display: flex;
            align-items: center;
            padding: 4px 12px 4px 6px;
            background: rgb(243, 245, 246);
            position: relative;
            white-space: nowrap;
            border-radius: 20px;
            cursor: pointer;

            &-icon {
                width: 24px;
            }

            .name {
                font-size: 14px;
                color: #8697a2;
                margin-left: 10px;
                flex: 1;
                text-align: left;
            }

            .arrow {
                width: 16px;
                height: 16px;
                display: flex;
                align-items: center;
                justify-content: center;

                img {
                    width: 60%;
                }
            }
        }

        .footer-pz-list {
            width: 180px;
            display: flex;
            flex-wrap: wrap;

            img {
                height: 28px;
                filter: grayscale(100%) opacity(0.5);
                margin-bottom: 16px;
                margin-right: 12px;

                &:nth-child(1) {
                    width: 60px;
                }

                &:nth-child(4),
                &:nth-child(5) {
                    height: 22px;
                }

                &:hover {
                    filter: grayscale(0) opacity(1);
                }
            }
        }
    }
}

.footer-title {
    margin-bottom: 20px;
    text-align: left;
    display: flex;

    span {
        font-size: 14px;
        font-weight: bold;
    }

    .icon {
        width: 16px;
        margin-left: 10px;
    }
}

.bk-transter {
    display: flex;
    flex-direction: column;

    &-row {
        display: flex;
        justify-content: space-between;
        margin-top: 0;
    }

    &-list {
        display: flex;

        &.bk-transter-flex {
            flex-direction: column;
        }

        img {
            height: 34px;
            margin-right: 10px;
            filter: grayscale(100%) opacity(0.5);
            max-width: 110px;

            &.one,
            &:hover {
                filter: grayscale(0) opacity(1);
            }
        }
    }

}

.bk-footer-copy {
    text-align: left;
    padding-bottom: 60px;
    color: #8697a2;
    font-size: 12px;
}

.infoCenter {
    background: #f3f5f6;
}
</style>