import i18n from '@/lang'
import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    gamePlayt: null,
    isLogin: localStorage.getItem('AppToken') || false,
    gameLink: [
      { number: '1', type: 'casino', name: i18n.t('store.index.138897-0') },
      { number: '2', type: 'esports', name: i18n.t('store.index.138897-1') },
      { number: '3', type: 'lottery', name: i18n.t('store.index.138897-2') },
      { number: '4', type: 'sports', name: i18n.t('store.index.138897-3') },
      { number: '5', type: 'slots', name: i18n.t('store.index.138897-4') },
      { number: '6', type: 'fish', name: i18n.t('store.index.138897-5') },
      { number: '7', type: 'chess', name: i18n.t('store.index.138897-6') },
    ],
    registerItem: null,
    baseInfo: null,
    userInfo: null,
    accountMoney: null,
    messageInfo: null,
    langMenus: false,
    languages: [
      { type: 'zh', id: '1', name: '简体中文' },
      { type: 'en', id: '3', name: 'English' },
      { type: 'vn', id: '5', name: 'Việt Nam' }
    ],
  },
  getters: {
  },
  mutations: {
    setGamePlayt(state, val) {
      state.gamePlayt = val
    },
    setHasLogin(state, val) {
      state.isLogin = val
    },
    setRegisterItem(state, val) {
      state.registerItem = val
    },
    setBaseInfo(state, val) {
      state.baseInfo = val
    },
    setUserInfo(state, val) {
      state.userInfo = val
    },
    setAccountMoney(state, val) {
      state.accountMoney = val
    },
    setMessageInfo(state, val) {
      state.messageInfo = val
    },

    setLangMenus(state, val) {
      state.langMenus = val
    }
  },
  actions: {
    setGamePlaytAc(context, val) {
      context.commit('setGamePlayt', val)
    },
    setHasLoginAc(context, val) {
      context.commit('setHasLogin', val)
    },
    setRegisterItemAc(context, val) {
      context.commit('setRegisterItem', val)
    },
    setBaseInfoAc(context, val) {
      context.commit('setBaseInfo', val)
    },
    setUserInfoAc(context, val) {
      context.commit('setUserInfo', val)
    },
    setAccountMoneyAc(context, val) {
      context.commit('setAccountMoney', val)
    },
    setMessageInfoAc(context, val) {
      context.commit('setMessageInfo', val)
    },

    setLangMenusAc(context, val) {
      context.commit('setLangMenus', val)
    }
  },
  modules: {
  }
})
