<template>
    <section class="message">
        <div class="message-main">
            <div class="message-title">{{ $t('components.HomeMessage.927503-0') }}</div>
            <div class="message-close el-icon-close" @click="close"></div>
            <ul>
                <li v-for="(item, index) in dataList" :key="index" @click="$router.push('/myaccount/message?id=' + item.id)">
                    <p v-html="item.content"></p>
                    <span>{{ item.createTime }}</span>
                </li>
            </ul>
        </div>
    </section>
</template>
<script>
export default {
    props: {
        dataList: {
            type: Array,
            default: () => []
        },
        visible: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
        }
    },
    methods: {
        close() {
            this.$emit('update:visible', false)
        },
    }
}
</script>
<style lang="scss" scoped>
.message {
    position: fixed;
    z-index: 11;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgba(0, 0, 0, .7);

    &-main {
        background: #f3f5f6;
        border-radius: 10px;
        font-size: 16px;
        padding: unset;
        width: 700px;
        padding-bottom: 30px;
        position: relative;
        transform: translateY(-30%);

        ul {
            padding: 0 30px;
            max-height: 400px;
            overflow-y: auto;
        }

        li {
            margin-bottom: 10px;
            border-radius: 10px;
            background: white;
            padding: 24px;
            text-align: left;
            cursor: pointer;

            span {
                font-size: 14px;
                color: #8697a2;
                margin-top: 16px;
                display: block;
            }
        }
    }

    &-title {
        text-align: center;
        color: #8697a2;
        padding: 30px 0;
        font-size: 18px;
    }

    &-close {
        width: 34px;
        vertical-align: middle;
        background: #ffffff;
        border-radius: 50%;
        padding: 8px;
        position: absolute;
        right: -10px;
        top: -10px;
        font-size: 18px;
        box-shadow: -1px 2px 4px rgba(0, 0, 0, .1);
        color: #8697a2;
        cursor: pointer;
    }

}
</style>