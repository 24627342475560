<template>
    <section class="download">
        <div class="download-main" v-if="tragetType">
            <div class="download-close el-icon-close" @click="close"></div>
            <div class="download-left">
                <div class="download-title">
                    {{ (appType === 'ios' ? 'iOS' : 'Andriod') + $t('components.Download.907806-0') }}
                </div>
                <div class="download-step">
                    <span>{{ $t('components.Download.907806-1') }}{{ tragetIndex + 1 }}</span>
                    <p v-html="tragetType[tragetIndex].sec"></p>
                </div>
                <div class="download-nav">
                    <div class="prev el-icon-arrow-left" @click="downPrev('prev')"></div>
                    <div class="context">{{ tragetIndex + 1 }} / {{ tragetType.length }}</div>
                    <div class="next el-icon-arrow-right" @click="downPrev('next')"></div>
                </div>
            </div>
            <div class="download-right">
                <el-carousel indicator-position="none" ref="carousel" @setActiveItem="tabItem" :initial-index="tragetIndex"
                    :autoplay="false">
                    <el-carousel-item v-for="(item, index) in tragetType" :key="index">
                        <img :src="item.img" />
                    </el-carousel-item>
                </el-carousel>
            </div>
            <div class="download-inline">
                <span v-for="(item, index) in tragetType" :key="index" @click="tabItemImg(index)"
                    :class="{ hover: tragetIndex === index }"></span>
            </div>
        </div>
    </section>
</template>
<script>
export default {
    props: {
        appType: {
            type: String,
            default: 'ios'
        },
        visible: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            downloadList: [
                {
                    type: 'ios',
                    list: [
                        {
                            img: require('@/assets/images/download/nav.jpeg'),
                            sec: this.$t('components.Download.907806-2')
                        },
                        {
                            img: require('@/assets/images/download/nav02.jpeg'),
                            sec: this.$t('components.Download.907806-3')
                        },
                        {
                            img: require('@/assets/images/download/nav03.jpeg'),
                            sec: this.$t('components.Download.907806-4')
                        },
                        {
                            img: require('@/assets/images/download/nav04.jpeg'),
                            sec: this.$t('components.Download.907806-5')
                        }
                    ]
                },
                {
                    type: 'apk',
                    list: [
                        {
                            img: require('@/assets/images/download/nav06.jpeg'),
                            sec: this.$t('components.Download.907806-6')
                        },
                        {
                            img: require('@/assets/images/download/nav05.jpeg'),
                            sec: this.$t('components.Download.907806-7')
                        }
                    ]
                }
            ],
            tragetType: null,
            tragetIndex: 0,
        }
    },
    mounted() {
        this.initAppType()
    },
    methods: {
        downPrev(val) {
            if (val === 'prev') {
                if (this.tragetIndex == 0) return
                this.tragetIndex--

            } else {
                if (this.tragetType.length - 1 == this.tragetIndex) return
                this.tragetIndex++
            }
            this.tabItem(this.tragetIndex)
        },
        tabItem(index) {
            this.$refs.carousel.setActiveItem(index)
        },
        tabItemImg(index) {
            this.tabItem(index)
        },
        initAppType() {
            this.tragetType = this.downloadList.find(item => item.type === this.appType).list
        },
        close() {
            this.$emit('update:visible', false)
        },
    }
}
</script>

<style lang="scss" scoped>
.download {
    position: fixed;
    z-index: 11;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgba(0, 0, 0, .7);

    &-main {
        background: white;
        border-radius: 10px;
        font-size: 16px;
        padding: 0 0 0 60px;
        width: 700px;
        padding-bottom: 30px;
        position: relative;
        transform: translateY(-30%);
        display: flex;
    }

    &-left {
        height: auto;
        display: flex;
        flex-direction: column;
        text-align: left;
        position: absolute;
        top: 60px;
        bottom: 80px;
        width: 48%;
        height: auto;
        z-index: 11;
    }

    &-title {
        font-size: 30px;
        color: #0f161f;
        font-weight: bold;
        word-break: break-word;
        line-height: 1;
    }

    &-step {
        flex: 1;
        margin: 80px 0;

        p {
            color: #8697a2;
            margin-top: 10px;
        }
    }

    &-right {
        padding-left: 40%;
        width: 100%;

    }

    &-close {
        width: 34px;
        vertical-align: middle;
        background: #ffffff;
        border-radius: 50%;
        padding: 8px;
        position: absolute;
        right: -10px;
        top: -10px;
        font-size: 18px;
        box-shadow: -1px 2px 4px rgba(0, 0, 0, .1);
        color: #8697a2;
        cursor: pointer;
        z-index: 11;
    }

    &-nav {
        display: flex;
        position: relative;
        width: 100px;
        justify-content: center;
    }

    &-inline {
        display: flex;
        align-items: center;
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        bottom: 30px;
        z-index: 11;

        span {
            height: 9px;
            width: 9px;
            background: #e1e1e1;
            border-radius: 50%;
            margin: 0 6px;
            display: block;
            cursor: pointer;

            &.hover {
                background: #ff8a00;
            }
        }
    }

}

/deep/.download-right {
    .el-carousel__container {
        height: 612px;
    }

    .el-carousel__arrow {
        display: none !important;
    }
}
</style>