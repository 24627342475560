import Vue from 'vue'
import ElementUI from 'element-ui'

import locale from 'element-ui/lib/locale/lang/vi.js'
import App from './App.vue'
import router from './router'
import store from './store'
import api from "./api/index";
import mixin from "./mixins/index"
import 'element-ui/lib/theme-chalk/index.css';
import '@/assets/css/all.scss'
import Router from 'vue-router'
import i18n from '@/lang'
import loadong from './utils/loading'

Vue.use(loadong)
const originalPush = Router.prototype.push
Router.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}
Vue.mixin(mixin);
Vue.use(ElementUI, { locale })
Vue.config.productionTip = false
Vue.prototype.$Api = api;

new Vue({
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount('#app')
