<template>
  <div class="layout">
    <!-- banner -->
    <section class="banner" v-if="banner">
      <el-carousel :interval="5000" trigger="click">
        <el-carousel-item v-for="(item, index) in banner" :key="index">
          <img
            :src="item.imgUrl"
            class="banner-hei"
            @click="openBanner(item)"
          />
        </el-carousel-item>
      </el-carousel>
    </section>
    <!-- activity -->
    <section class="bk-wid mg-top30">
      <div class="bk-wid-134 bk-activity" v-if="activityList">
        <div
          class="prev el-icon-arrow-left"
          v-if="acIndex !== 0"
          @click="activity('prev', 8)"
        ></div>
        <div
          class="next el-icon-arrow-right"
          v-if="acIndex !== 8 - 4"
          @click="activity('next', 8)"
        ></div>
        <div class="bk-activity-box">
          <ul :style="{ transform: 'translateX(' + acLeftVal + 'px)' }">
            <li
              v-for="(item, index) in activityList"
              :key="index"
              @click="openBanner(item)"
            >
              <img :src="item.listImgUrl" />
            </li>
          </ul>
        </div>
      </div>
    </section>
    <!-- news -->
    <section class="bk-wid mg-top30">
      <div class="bk-wid-134 bk-news">
        <div class="bk-news-main" v-if="messageList">
          <div
            class="bk-news-row"
            v-for="(item, index) in messageList"
            :key="index"
            @click="isMessage = true"
            :class="{ hover: index === newsIndex }"
          >
            <div class="bk-news-icon">{{ $t("views.index.178880-0") }}</div>
            <div class="bk-news-text" v-html="item.scrollContent"></div>
          </div>
        </div>
        <div class="bk-news-more" @click="isMessage = true">
          {{ $t("views.index.178880-1") }}
        </div>
      </div>
    </section>
    <!-- regsiter guide -->
    <section class="bk-wid">
      <div class="bk-wid-134 bk-regsiter">
        <div class="bk-regsiter-title">
          <span>{{ $t("views.index.178880-2") }}</span>
          <h3>{{ $t("views.index.178880-3") }}</h3>
        </div>
        <div class="bk-regsiter-step">
          <ul>
            <li>
              <img src="@/assets/images/regsiter01.svg" />
              <p>
                {{
                  $t("views.index.178880-4") + $t("views.index.178880-5")
                }}，<br />{{ $t("views.index.178880-6") }}
              </p>
            </li>
            <li>
              <img src="@/assets/images/regsiter02.svg" />
              <p>{{ $t("views.index.178880-7") }}</p>
            </li>
            <li>
              <img src="@/assets/images/regsiter03.svg" />
              <p>{{ $t("views.index.178880-8") }}</p>
            </li>
            <li>
              <img src="@/assets/images/regsiter04.svg" />
              <p>{{ $t("views.index.178880-9") }}</p>
            </li>
          </ul>
        </div>
        <div class="bk-regsiter-text">
          <ul>
            <li>{{ $t("views.index.178880-10") }}</li>
            <li>{{ $t("views.index.178880-11") }}</li>
            <li>{{ $t("views.index.178880-12") }}</li>
            <li>{{ $t("views.index.178880-13") }}</li>
          </ul>
        </div>
      </div>
    </section>
    <!-- hots game -->
    <section class="bk-wid" v-if="hotGameList && hotGameList.length">
      <div class="bk-wid-134 bk-hotsGame">
        <div class="bk-hotsGame-title">
          <span>{{ $t("views.index.178880-14") }}</span>
          <p @click="$router.push('/game/esports/all')">
            {{ $t("views.index.178880-15") }}
          </p>
        </div>
        <div class="bk-hotsGame-scroll">
          <div
            class="prev el-icon-arrow-left"
            v-if="hotsGameLeftVal !== 0"
            @click="tabGameScroll('prev', 'hots')"
          ></div>
          <div
            class="next el-icon-arrow-right"
            v-if="hotsGameLeftVal === 0"
            @click="tabGameScroll('next', 'hots')"
          ></div>
          <div class="bk-hotsGame-list">
            <ul :style="{ transform: 'translateX(' + hotsGameLeftVal + 'px)' }">
              <li v-for="(item, index) in hotGameList" :key="index">
                <div
                  class="bk-hotsGame-icon"
                  @click="isLoginTip(openGame(item))"
                >
                  <img :src="item.gameImgUrl" />
                  <div class="play">
                    <span></span>
                  </div>
                </div>
                <div class="bk-hotsGame-text">
                  <span>{{ item.gameName }}</span>
                  <p>{{ item.platType }}</p>
                  <div class="iconState">
                    <i class="hots" v-if="item.isHot !== 0">{{
                      $t("views.index.178880-16")
                    }}</i>
                    <i v-if="item.isNew !== 0">{{
                      $t("views.index.178880-17")
                    }}</i>
                  </div>
                  <div class="bk-hotsGame-text-hl">
                    {{ $t("views.index.178880-18") }}
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </section>
    <!-- by game -->
    <section class="bk-wid mg-top40">
      <div class="bk-wid-134 bk-hotsGame">
        <div class="bk-hotsGame-title">
          <span>{{ $t("views.index.178880-19") }}</span>
          <p @click="$router.push('/game/fish/all')">
            {{ $t("views.index.178880-15") }}
          </p>
        </div>
        <div class="bk-hotsGame-scroll">
          <div
            class="prev el-icon-arrow-left"
            v-if="ylGameLeftVal !== 0"
            @click="tabGameScroll('prev', 'yl')"
          ></div>
          <div
            class="next el-icon-arrow-right"
            v-if="ylGameLeftVal === 0"
            @click="tabGameScroll('next', 'yl')"
          ></div>
          <div class="bk-hotsGame-list">
            <ul :style="{ transform: 'translateX(' + ylGameLeftVal + 'px)' }">
              <li v-for="(item, index) in BYGameList" :key="index">
                <div
                  class="bk-hotsGame-icon"
                  @click="isLoginTip(openGame(item))"
                >
                  <img :src="item.gameImgUrl" />
                  <div class="play">
                    <span></span>
                  </div>
                </div>
                <div class="bk-hotsGame-text">
                  <span>{{ item.gameName }}</span>
                  <p>{{ item.platType }}</p>
                  <div class="iconState">
                    <i class="hots" v-if="item.isHot !== 0">{{
                      $t("views.index.178880-16")
                    }}</i>
                    <i v-if="item.isNew !== 0">{{
                      $t("views.index.178880-17")
                    }}</i>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </section>
    <!-- download -->
    <section class="bk-wid mg-top110">
      <div class="bk-wid-134 bk-download">
        <div class="bk-hotsGame-title">
          <span>{{ $t("views.index.178880-20") }}</span>
        </div>
        <div class="bk-download-main">
          <div class="bk-download-left">
            <h3>
              {{ $t("views.index.178880-21") }}<br />
              {{ $t("views.index.178880-22") }}<br />
              {{ $t("views.index.178880-23") }}
              <img src="@/assets/images/bk8.png" class="logo" />
            </h3>
            <p class="small">{{ $t("views.index.178880-24") }}</p>
            <ul>
              <li>
                <QRcode
                  class="code"
                  v-if="baseInfo && baseInfo[22]"
                  :text="baseText('iosApp')"
                  :width="100"
                  :height="100"
                />
                <div
                  class="bk-download-platy"
                  @click="downloadLink(baseText('iosApp'))"
                >
                  <img class="ios" src="@/assets/images/ios.svg" />
                  <span>Apple iOS</span>
                </div>
                <p class="link" @click="openDownload('ios')">
                  {{ $t("views.index.178880-25") }}
                </p>
              </li>
              <li>
                <QRcode
                  class="code"
                  v-if="baseInfo && baseInfo[22]"
                  :text="baseText('androidApp')"
                  :width="100"
                  :height="100"
                />
                <div
                  class="bk-download-platy"
                  @click="downloadLink(baseText('androidApp'))"
                >
                  <img class="apk" src="@/assets/images/apk.svg" />
                  <span>{{ $t("views.index.178880-26") }}</span>
                </div>
                <p class="link" @click="openDownload('apk')">
                  {{ $t("views.index.178880-25") }}
                </p>
              </li>
            </ul>
          </div>
          <div class="bk-download-right">
            <ul>
              <li class="one">
                <span>{{ $t("views.index.178880-27") }}</span>
                <p>{{ $t("views.index.178880-28") }}</p>
              </li>
              <li class="three">
                <span>{{ $t("views.index.178880-29") }}</span>
                <p>{{ $t("views.index.178880-30") }}</p>
              </li>
              <li class="two">
                <span>{{ $t("views.index.178880-31") }}</span>
                <p>{{ $t("views.index.178880-32") }}</p>
              </li>
              <li class="four">
                <span>{{ $t("views.index.178880-33") }}</span>
                <p>{{ $t("views.index.178880-34") }}</p>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </section>
    <!-- partner -->
    <section class="bk-wid mg-top60">
      <div class="bk-wid-134 bk-hotsGame">
        <div class="bk-hotsGame-title">
          <span>{{ $t("views.index.178880-35") }}</span>
          <p @click="$router.push('/about')">
            {{ $t("views.index.178880-15") }}
          </p>
        </div>
        <div class="bk-partner">
          <ul
            ref="nartnerscroll"
            :style="{ transform: 'translateX(' + partnerVal + 'px)' }"
          >
            <li v-for="(item, index) in partnerList" :key="index">
              <img :src="item" />
            </li>
          </ul>
        </div>
      </div>
    </section>
    <!-- client -->
    <MessageList
      v-if="isMessage"
      :visible.sync="isMessage"
      :dataList="messageList"
    />
    <Download
      v-if="isDownloadShow"
      :visible.sync="isDownloadShow"
      :appType="downloadType"
    />
  </div>
</template>
<script>
import QRcode from "@/components/QRCode.vue";
import MessageList from "@/components/HomeMessage.vue";
import Download from "@/components/Download.vue";
export default {
  components: { QRcode, MessageList, Download },
  name: "home",
  data() {
    return {
      acLeftVal: 0,
      acIndex: 0,
      newsIndex: 0,
      banner: null,
      messageList: null,
      isMessage: false,
      activityList: null,
      hotGameList: null,
      BYGameList: null,
      hotsGameLeftVal: 0,
      ylGameLeftVal: 0,
      partnerTimer: null,
      partnerVal: 0,
      partnerIndex: 0,
      partnerList: null,
      partnerTimer2: null,
    };
  },
  watch: {
    userInfo(val) {
      if (val) {
        this.checkName();
      }
    },
  },
  mounted() {
    this.initNews();
    this.getSilde();
    this.getTop50MessageList();
    this.getActivityList();
    this.getGameListByGameType();
    this.getGameListBY();
    this.partnerAuto();
    this.initInfo();
    this.checkName();
  },
  methods: {
    // 检测是否设置姓名
    checkName() {
      if (this.isLogin && this.userInfo && !this.userInfo.realName) {
        this.$confirm(
          "检测到您还未设置姓名,请先去设置,方便更好的体验～",
          "温馨提示",
          {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "none",
          }
        )
          .then(() => {
            this.$router.push("/myaccount/userinfo");
          })
          .catch(() => {
            console.log("取消");
          });
      }
    },
    // 合作伙伴logo数据初始化
    initInfo() {
      let arr = [];
      let arr2 = [];
      for (let i = 1; i < 22; i++) {
        let obj = "";
        let obj7 = "";
        if (i === 7) {
          obj = require("@/assets/images/link/07.png");
          arr2.push(obj);
          arr.push(obj);
        } else if (i <= 7) {
          obj7 = require("@/assets/images/link/0" + i + ".svg");
          arr2.push(obj7);
          arr.push(obj7);
        } else {
          obj = require("@/assets/images/link/" +
            (i > 9 ? i : "0" + i) +
            ".svg");
          arr.push(obj);
        }
      }
      this.partnerList = arr.concat(arr2);
    },
    partnerAuto() {
      this.partnerTimer = setTimeout(() => {
        this.partnerIndex++;
        this.partnerVal = -this.partnerIndex * 191;
        this.partnerAuto();
        if (this.partnerIndex === 22) {
          const dom = this.$refs.nartnerscroll;
          dom.style.transition = "";
          this.partnerTimer2 = setTimeout(() => {
            dom.style.transition = "all .5s";
          }, 100);
          this.partnerIndex = 0;
          this.partnerVal = 0;
        }
      }, 3000);
    },
    tabGameScroll(val, type) {
      if (type === "yl") {
        if (val === "prev") {
          this.ylGameLeftVal = 0;
        } else {
          this.ylGameLeftVal = "-1077";
        }
      } else {
        if (val === "prev") {
          this.hotsGameLeftVal = 0;
        } else {
          this.hotsGameLeftVal = "-1077";
        }
      }
    },
    openGame(val) {
      if (!this.isLogin) return;
      this.loadinggame = true;
      const params = {
        platType: val.platType,
        gameType: val.gameType,
        gameCode: val.gameCode,
        devices: 0,
      };
      this.$Api.getGameUrl(params).then((res) => {
        if (res) {
          this.loadinggame = false;
          const url = JSON.parse(res.data);
          window.open(url.url, "_blank");
        }
      });
    },
    getGameListBY() {
      let params = {
        pageIndex: 1,
        pageSize: 10,
        gameType: "6",
      };
      this.$Api.getGameListByGameType(params).then((res) => {
        if (res) {
          this.BYGameList = res.page.records;
        }
      });
    },
    // 游戏列表
    getGameListByGameType() {
      let params = {
        pageIndex: 1,
        pageSize: 10,
        isHot: 1,
      };
      this.$Api.getGameListByGameType(params).then((res) => {
        if (res) {
          this.hotGameList = res.page.records;
        }
      });
    },
    getActivityList() {
      this.$Api
        .getActivityList({ device: 1, pageIndex: 1, pageSize: 8 })
        .then((res) => {
          if (res) {
            this.activityList = res.activityList.records;
          }
        });
    },
    getTop50MessageList() {
      this.$Api.getTop50MessageList().then((res) => {
        if (res) {
          this.messageList = res.messageList;
        }
      });
    },
    getSilde() {
      this.$Api.getSilde({ position: 1, device: 1 }).then((res) => {
        if (res) {
          this.banner = res.slideList;
        }
      });
    },
    openBanner(val) {
      const value = val.activityId || val.id;
      if (value != null) {
        this.$router.push("/activity?id=" + value);
      }
    },
    activity(val, length) {
      if (val === "next") {
        if (this.acIndex >= length - 4) return;
        this.acIndex++;
        this.acLeftVal = -(338 * this.acIndex);
      } else {
        if (this.acIndex === 0) return;
        this.acIndex--;
        this.acLeftVal = -(338 * this.acIndex);
      }
    },
    initNews() {
      setTimeout(() => {
        this.initNews();
        this.newsIndex++;
        if (this.newsIndex === this.messageList.length) {
          this.newsIndex = 0;
        }
      }, 4000);
    },
  },
  destroyed() {
    clearTimeout(this.partnerTimer);
  },
};
</script>
<style lang="scss" scoped>
.layout {
  width: 100%;
  background: white;
}

/deep/.banner {
  background: #0a1624;

  .el-carousel__container {
    height: 525px;
  }

  .el-carousel__indicators--horizontal {
    left: 50px;
    bottom: 16px;
    transform: none;

    .el-carousel__button {
      width: 9px;
      height: 9px;
      border-radius: 9px;
    }
  }

  .el-carousel__indicator.is-active .el-carousel__button {
    background: #fdb813;
    width: 29px;
  }

  .el-carousel__arrow {
    display: none;
    opacity: 0;
  }
}

.bk-activity {
  position: relative;

  &-box {
    overflow: hidden;
  }

  ul {
    white-space: nowrap;
    display: flex;
    align-items: center;
    transition: all 0.4s;

    li {
      width: 326px;
      height: 105px;
      flex: none;
      margin-right: 12px;
      cursor: pointer;

      img {
        width: 100%;
        height: 105px;
        border-radius: 6px;
      }
    }
  }
}

.bk-news {
  display: flex;
  align-items: center;
  justify-content: center;

  &-icon {
    background: #0c4da2;
    width: 44px;
    height: 19px;
    font-size: 12px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    border-radius: 4px;
  }

  &-main {
    margin: 0 6px;
    text-align: left;
    position: relative;
    height: 22px;
    overflow: hidden;
    width: 40%;
    max-width: 580px;
    min-width: 280px;
  }

  &-row {
    position: absolute;
    top: 0;
    height: 22px;
    display: flex;
    align-items: center;
    opacity: 0;
    transform: translateY(22px);
    width: 100%;

    &.hover {
      opacity: 1;
      transform: translateY(0);
      transition: all 1.8s ease-in-out;
    }
  }

  &-text {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    width: calc(100% - 40px);
    margin-left: 6px;
  }

  &-more {
    color: #8697a2;
    text-decoration: underline;
    cursor: pointer;
  }
}

.bk-regsiter {
  padding: 100px 0;

  &-title {
    margin: 0 auto 50px;

    span {
      font-size: 18px;
    }

    h3 {
      font-size: 36px;
    }
  }

  &-step {
    width: 1200px;
    margin: 0 auto;

    ul {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;

      li {
        width: 23%;
        height: 168px;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        border: 1px solid red;
        padding: 15px;
        border-radius: 10px;

        &:nth-child(2) {
          border: 1px solid #67e49e;
        }

        &:nth-child(3) {
          border: 1px solid #f7921a;
        }

        &:nth-child(4) {
          border: 1px solid #fbb813;
        }

        img {
          height: 75px;
        }

        p {
          margin: 15px 5px 5px;
          font-size: 16px;
        }
      }
    }
  }

  &-text {
    width: 1200px;
    margin: 20px auto 0;
    background: url("@/assets/images/regsiter05.jpeg") no-repeat top center;
    background-size: contain;

    ul {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding-top: 40px;

      li {
        width: 276px;
        text-align: center;
        font-size: 18px;
        color: red;

        &:nth-child(2) {
          color: #67e49e;
        }

        &:nth-child(3) {
          color: #f7921a;
        }

        &:nth-child(4) {
          color: #fbb813;
        }
      }
    }
  }
}

.bk-hotsGame {
  &-title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: #0f161f;
    margin: 0 0 22px;

    span {
      font-size: 18px;
      font-weight: bold;
    }

    p {
      font-size: 16px;
      text-decoration: underline;
      cursor: pointer;
    }
  }

  &-scroll {
    position: relative;

    .prev,
    .next {
      top: 36%;
    }
  }

  &-list {
    position: relative;
    overflow: hidden;

    ul {
      white-space: nowrap;
      display: flex;
      align-items: center;
      transition: all 0.4s;
    }

    li {
      width: 230px;
      flex: none;
      margin-right: 13px;

      img {
        width: 100%;
        border-radius: 10px;
      }
    }
  }

  &-icon {
    width: 230px;
    height: 144px;
    position: relative;
    cursor: pointer;
    overflow: hidden;

    &:hover {
      .play {
        transform: translateY(0);
      }
    }

    .play {
      background: rgba(255, 255, 255, 0.3);
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      transform: translateY(100%);
      transition: all 0.4s;

      span {
        background-image: url("@/assets/images/play.svg");
        background-repeat: no-repeat;
        background-position: center center;
        background-size: 30% 50%;
        background-color: rgba(0, 0, 0, 0.5);
        width: 44px;
        height: 44px;
        border-radius: 50%;
        display: flex;
      }
    }
  }

  &-text {
    margin-top: 13px;
    text-align: left;
    padding-left: 10px;
    position: relative;

    span {
      font-size: 15px;
    }

    p {
      color: #8697a2;
      font-size: 13px;
    }

    .iconState {
      display: flex;
      align-items: center;
      position: absolute;
      right: 0;
      top: 0;
    }

    i {
      font-size: 12px;
      background: #e0092c;
      padding: 4px 6px;
      border-radius: 20px;
      text-align: center;
      color: #fff;
      font-style: normal;
      font-family: helvetica neue, helvetica-regular;

      &.hots {
        background: #ff650f;
      }
    }

    &-hl {
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 8px;
      width: fit-content;
      background: #0088f3;
      font-size: 13px;
      margin: 3px 0 0 -10px;
      color: #fff;
      padding: 4px 10px;
    }
  }
}

.bk-download {
  &-main {
    display: flex;
    justify-content: space-between;
  }

  &-left {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-top: 34px;
    width: 40%;

    h3 {
      font-size: 36px;
      text-align: left;
      line-height: 1;
      font-weight: bold;
      position: relative;
      padding-right: 140px;
      white-space: nowrap;

      .logo {
        position: absolute;
        right: 0;
        width: 72px;
        top: 0;
      }
    }

    .small {
      color: #8697a2;
      font-size: 14px;
      margin-top: 36px;
    }

    ul {
      display: flex;
      align-items: center;
      margin-top: 69px;

      li {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-right: 47px;

        .code {
          width: 120px;
          height: 120px;
          background: white;
          border-radius: 10px;
          border: 1px solid #dcdcdc;
          padding: 10px;
        }

        .link {
          color: #0088f3;
          font-size: 16px;
          margin-top: 10px;
        }
      }
    }
  }

  &-platy {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 134px;
    background: #f3f6f6;
    color: #0f161f;
    border-radius: 20px;
    margin: 16px 0 0;
    font-size: 14px;
    cursor: pointer;
    padding: 5px;

    .ios,
    .apk {
      width: 16px;
      margin-right: 6px;
    }
  }

  &-right {
    height: 778px;
    background: url("@/assets/images/app.png") no-repeat;
    background-position: -8% 180px;
    background-size: 65%;
    display: flex;
    justify-content: space-between;
    padding-left: 288px;

    ul {
      display: grid;
      grid-template-columns: repeat(2, 206px);
      grid-template-rows: repeat(2, 277px);
      row-gap: 10px;
      column-gap: 10px;

      li {
        height: 277px;
        background-image: url("@/assets/images/downloadbg01.jpeg");
        background-repeat: no-repeat;
        background-size: contain;
        border-radius: 15px;
        padding: 175px 15px 0 20px;
        color: white;
        text-align: left;

        span {
          font-size: 18px;
        }

        p {
          // margin-top: 8px;
          font-size: 14px;
        }

        &.two {
          background-image: url("@/assets/images/downloadbg02.jpeg");
        }

        &.three {
          background-image: url("@/assets/images/downloadbg03.jpeg");
        }

        &.four {
          background-image: url("@/assets/images/downloadbg04.jpeg");
        }

        &:nth-child(2),
        &:nth-child(4) {
          margin-top: 112px;
        }
      }
    }
  }
}

.bk-partner {
  height: 133px;
  background: #f3f5f6;
  border-radius: 10px;
  overflow: hidden;
  padding: 16px;
  display: flex;
  align-items: center;

  ul {
    display: flex;
    align-items: center;
    transition: all 0.5s;
  }

  li {
    flex: none;
    width: 178px;
    height: 80px;
    margin: 0 13px 0 0;
    background: #fff;
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;

    img {
      width: 100%;
      max-width: 130px;
      max-height: 100%;
      max-height: 60px;
    }
  }
}

.bk-client {
  background: #f3f5f6;
  padding: 40px 28px 30px;
  border-radius: 10px;

  &-list {
    position: relative;
  }

  &-scroll {
    position: relative;
    overflow: hidden;

    ul {
      white-space: nowrap;
      display: flex;
      align-items: center;
    }

    li {
      flex: none;
      width: 428px;
      white-space: initial;
      padding: 0 40px;
      height: 376px;
      display: flex;
      align-items: center;
      flex-direction: column;
      justify-content: center;
      border-right: 1px solid;
      border-image: linear-gradient(
          to bottom,
          #f3f5f6 0%,
          #dde0e0 20%,
          #dde0e0 80%,
          #f3f5f6 100%
        )
        1 100%;

      h3 {
        font-size: 36px;
        margin-bottom: 40px;
      }

      p {
        font-size: 16px;
        color: #8697a2;
        margin-bottom: 24px;
      }

      img {
        width: 16px;
      }
    }
  }
}

.bk-news {
  ul {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;

    li {
      padding: 20px;
      width: 416px;
      background: white;
      border-radius: 10px;

      img {
        width: 100%;
      }
    }

    .main {
      min-height: 300px;
      text-align: left;
      margin-top: 32px;

      span {
        display: block;
        font-size: 18px;
        font-weight: bold;
        line-height: 1;
      }

      p {
        margin: 24px 0 0 0;
        font-size: 14px;
        color: #8697a2;
      }
    }

    .time {
      color: #8697a2;
      font-size: 12px;
      text-align: left;
    }
  }
}

.banner-hei {
  height: 100%;
  cursor: pointer;
}
</style>
