import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '@/views/index.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: Home
  },
  { //平台类型
    path: '/game',
    name: 'sports',
    component: () => import('../views/game/sports.vue'),
    require: '/game/:gamePlayt/:gameName',
    children: [
      {
        path: '/game/:gamePlayt/:gameName',
        component: () => import('../views/game/sportsRoom.vue'),
      }
    ]
  },
  {
    path: '/activity',
    name: 'activity',
    component: () => import('../views/activity/index.vue')
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('../views/login.vue')
  },
  {
    path: '/register',
    name: 'register',
    component: () => import('../views/register.vue')
  },
  {
    path: '/download',
    name: 'download',
    component: () => import('../views/download.vue')
  },
  {
    path: '/about',
    name: 'about',
    component: () => import('../views/aboutus/index.vue')
  },
  {
    path: '/contact',
    name: 'contact',
    component: () => import('../views/contact/index.vue')
  },
  {
    path: '/responsible-gaming',
    name: 'responsible',
    component: () => import('../views/responsible/index.vue')
  },
  {
    path: '/info-center/:mode',
    name: 'infocenter',
    component: () => import('../views/infoCenter/index.vue'),
  },
  {
    path: '/sponsors',
    name: 'sponsors',
    component: () => import('../views/sponsors/index.vue'),
  },
  {
    path: '/myaccount',
    name: 'myaccount',
    component: () => import('../views/myaccount/index.vue'),
    require: '/myaccount/deposit',
    children: [
      {
        path: '/myaccount/deposit',
        component: () => import('../views/myaccount/deposit/deposit.vue'),
        meta: {
          layout: 'deposit'
        }

      },
      {
        path: '/myaccount/transfer',
        component: () => import('../views/myaccount/transfer/index.vue'),
        meta: {
          layout: 'transfer'
        }
      },
      {
        path: '/myaccount/withdrawal',
        component: () => import('../views/myaccount/withdrawal/index.vue'),
        meta: {
          layout: 'withdrawal'
        }
      },
      {
        path: '/myaccount/wallet',
        component: () => import('../views/myaccount/wallet/index.vue'),
        meta: {
          layout: 'wallet'
        }
      },
      {
        path: '/myaccount/wallet/addBankCard',
        component: () => import('../views/myaccount/wallet/addBankCard.vue'),
        meta: {
          layout: 'wallet'
        }
      },
      {
        path: '/myaccount/wallet/addUsdtCard',
        component: () => import('../views/myaccount/wallet/addUsdtCard.vue'),
        meta: {
          layout: 'wallet'
        }
      },
      {
        path: '/myaccount/wallet/addThreeCard',
        component: () => import('../views/myaccount/wallet/addThreeCard.vue'),
        meta: {
          layout: 'wallet'
        }
      },
      {
        path: '/myaccount/vip',
        component: () => import('../views/myaccount/vip/index.vue'),
        meta: {
          layout: 'vip'
        }
      },
      {
        path: '/myaccount/vipView',
        component: () => import('../views/myaccount/vip/detail.vue'),
        meta: {
          layout: 'vip'
        }
      },
      {
        path: '/myaccount/welfate',
        component: () => import('../views/myaccount/welfate/index.vue'),
        meta: {
          layout: 'welfate'
        }
      },
      {
        path: '/myaccount/transRecord',
        component: () => import('../views/myaccount/transRecord/index.vue'),
        meta: {
          layout: 'transRecord'
        }
      },
      {
        path: '/myaccount/betRecord',
        component: () => import('../views/myaccount/betRecord/index.vue'),
        meta: {
          layout: 'betRecord'
        }
      },
      {
        path: '/myaccount/message',
        component: () => import('../views/myaccount/message/index.vue'),
        meta: {
          layout: 'message'
        }
      },
      {
        path: '/myaccount/feedbank',
        component: () => import('../views/myaccount/feedbank/index.vue'),
        meta: {
          layout: 'feedback'
        }
      },
      {
        path: '/myaccount/feedbank/list',
        component: () => import('../views/myaccount/feedbank/feedbackList.vue'),
        meta: {
          layout: 'feedback'
        }
      },
      {
        path: '/myaccount/feedbank/detail',
        component: () => import('../views/myaccount/feedbank/feedbackDetail.vue'),
        meta: {
          layout: 'feedback'
        }
      },
      {
        path: '/myaccount/userinfo',
        component: () => import('../views/myaccount/userinfo/index.vue'),
        meta: {
          layout: 'userinfo'
        }
      }
    ]
  },
  {
    path: '*',
    redirect: '/'
  }
]

const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes
})

export default router
